.main-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 9px;
  z-index: 2;

  @include tablet {
    padding-top: 22px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__nav {
    max-width: 375px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include tablet {
      display: none;
    }

    li {
      list-style-type: none;
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 1px;
      font-family: $font1;

      a {
        color: #FFFFFF;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .hamburger {
    display: none;
    background-image: url("../img/svg/hamburger.svg");
    width: 27px;
    height: 15px;
    cursor: pointer;

    @include tablet {
      display: block;
    }
  }

  .logo {
    height: 79px;
    width: 194px;
    background-image: url("../img/logo-white.png");

    @include tablet {
      height: 48px;
      width: 112px;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    @include mobile {
      width: 103px;
      height: 44px;
    }
  }
}