.subm {
	cursor: pointer;
	border: none;
	display: block;
	margin: 0 auto;
	transition: all 0.3s linear;
	&:hover,
	&:focus {
		transform: scale(1.02);
	}
}

.btn {
	width: 269px;
	@include flex-center;
	border-radius: 9px;
	font-family: $font1;
	font-size: 15px;
	letter-spacing: 1.5px;
	padding: 13px 0 14px;
	@include transition(all, .3s);

	&.btn_black {
		background: #1E1D30;
		border-radius: 9px;
		color: #fff;

		&:hover {
			background: #333252;
		}
	}

	&.btn_white {
		border: 1px solid #768CFA;
		border-radius: 9px;
		color: #768CFA;

		&:hover {
			border: 1px solid #fff;
			background: #768CFA;
			color: #fff;
		}
	}

	&.btn_blue {
		background: #768CFA;
		border: 1px solid #768CFA;
		box-shadow: -3px 7px 10px 2px #B6BAE5;
		color: #fff;
		cursor: pointer;
		&:hover {
			color: #768CFA;
			border: 1px solid #FFF;
			background: #FFF;

		}
	}
}