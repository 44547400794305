/*********************************************\
		COLORS
\*********************************************/
$font1: 'Montserrat', sans-serif;
$font2: 'Playfair-Display', serif;
$bouncing-icon-animation: scroll-bounce 2s ease infinite both;




$form-label-color: #000;

/*********************************************\
		FONTS
\*********************************************/
$form-label-font: Roboto;
$form-input-font: Roboto;