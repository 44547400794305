@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix')          format('embedded-opentype'),
           font-url('#{$file-path}.woff')                format('woff'),
           font-url('#{$file-path}.ttf')                 format('truetype'),
           font-url('#{$file-path}.svg##{$font-family}') format('svg');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix')               format('embedded-opentype'),
           url('#{$file-path}.woff')                     format('woff'),
           url('#{$file-path}.ttf')                      format('truetype'),
           url('#{$file-path}.svg##{$font-family}')      format('svg');
    }
  }
}

@mixin clearfix() {
 &:after {
  content: '';
  display: table;
  clear: both;
 }
}

@mixin media($min-width: false, $max-width: false) {
 @if $min-width and $max-width {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
   @content;
  }
 } @else if $min-width and not $max-width {
  @media screen and (min-width: $min-width) {
   @content;
  }
 } @else if $max-width and not $min-width {
  @media screen and (max-width: $max-width) {
   @content;
  }
 }
}

;

@mixin gradient($from-color, $to-color) {
 background-color: mix($from-color, $to-color); /* Fallback */
 background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
 background-image: -webkit-linear-gradient(top, $from-color, $to-color);
 background-image: -moz-linear-gradient(top, $from-color, $to-color);
 background-image: -ms-linear-gradient(top, $from-color, $to-color);
 background-image: -o-linear-gradient(top, $from-color, $to-color);
 -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='$from-color', EndColorStr='$to-color')";
}

@mixin tablet {
  @media (max-width: 1199px) {
    @content;
  }
}


@mixin mobile {
  @media (max-width: 719px) {
    @content;
  }
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition($attr, $time) {
  transition: $attr $time ease;
}

@mixin bg-defaults ($size) {
  background-size: $size;
  background-position-x: 50%;
  background-repeat: no-repeat;
}

@mixin circle ($radius) {
  width: $radius;
  height: $radius;
  border-radius: $radius;
}
