@keyframes scroll-bounce {
  0% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(5px);
  }

  30% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(0);
  }
}
