.main-footer {
  background: #FFFFFF;
  padding-top: 27px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  margin-top: -110px;

  @include mobile {
    margin-top: -160px;
  }

  .footer__top-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 41px;

    @include tablet {
      flex-direction: column;
    }

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .logo {
    width: 190px;
    height: 79px;
    background-image: url("../img/logo-b.png");
    background-size: 100%;
    background-repeat: no-repeat;

    @include mobile {
      width: 130px;
      height: 54px;
    }
  }

  .footer__nav {
    max-width: 375px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include tablet {
      margin-top: 17px;
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }

    li {
      list-style-type: none;
      font-size: 15px;
      color: #2A2A2C;
      letter-spacing: 1px;
      font-family: $font1;

      a {
        color: #2A2A2C;

        &:hover {
          text-decoration: underline;
        }
      }

      @include mobile {
        margin-bottom: 10px;
      }
    }
  }

  .footer__bottom-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 200px;

    @include tablet {
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }


  .bottom-left__txt {
    font-family: $font1;
    font-size: 15px;
    color: #2A2A2C;
    letter-spacing: 1px;
    max-width: 440px;

    @include tablet {
      text-align: center;
    }

    a {
      font-weight: 900;
      color: #2A2A2C;

      &:hover {
        text-decoration: underline;
      }
    }
  }


  .facebook {
    @include circle(36px);
    background-image: url("../img/svg/fb.svg");
    display: block;
    margin-top: 20px;
    @include transition(opacity, .4s);

    &:hover {
      opacity: .8;
    }
  }

  .footer__bottom-left {
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 17px;
    }
  }


  .footer__bottom-right {
    font-family: $font1;
    font-size: 15px;
    color: #2A2A2C;
    letter-spacing: 1px;

    .copyright {
      opacity: 0.5;

      @include tablet {
        text-align: center;
        margin-top: 20px;
      }
    }

    p {
      cursor: default;
    }

    div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      a {
        color: #2A2A2C;

        &:hover {
          text-decoration: underline;
        }
      }

      &:nth-child(1) {
        span {
          width: 14px;
          height: 19px;
          background-image: url("../img/svg/pin.svg");
          margin-right: 17px;
        }
      }

      &:nth-child(2) {
        span {
          width: 17px;
          height: 13px;
          background-image: url("../img/svg/letter.svg");
          margin-right: 15px;
          background-size: 100%;
        }
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        span {
          width: 15px;
          height: 15px;
          background-image: url("../../img/svg/telephone.svg");
          margin-right: 15px;
          background-size: 100%;
        }
      }
    }

    @include mobile {
      font-size: 14px;
    }
  }
}