.main-wrap {
  background-color: #E0E0E0;
  height: 4187px;

  @include tablet {
    height: 4997px;
  }

  @include mobile {
    height: 5307px;
  }
}

.sec1 {
  @include bg-defaults(1920px);
  background-image: url("../img/bg-sec1.png");
  padding-top: 106px;
  padding-bottom: 287px;
  position: relative;

  @include tablet {
    @include bg-defaults(1280px);
    background-image: url("../img/bg-sec1-t.png");
    padding-top: 70px;
    padding-bottom: 141px;
  }

  @include mobile {
    padding-top: 79px;
  }

  .sec1__txt1 {
    color: #FFFFFF;
    letter-spacing: 2.08px;
    margin-bottom: 98px;
    max-width: 425px;
    position: relative;
    padding-left: 15px;

    @include tablet {
      text-align: center;
      max-width: 308px;
      margin: 0 auto 44px;
      padding: 0;
      font-size: 10px;
      letter-spacing: 1.19px;
      line-height: 13px;
      font-weight: 600;
    }

    &::before {
      position: absolute;
      content: '';
      height: 33px;
      width: 2px;
      background-color: #ffffff;
      left: -7px;
      top: 15px;

      @include tablet {
        top: initial;
        bottom: -6px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 33px;
        height: 2px;
      }
    }

  }

  h1 {
    font-family: $font2;
    font-weight: bold;
    font-size: 85px;
    color: #FFFFFF;
    letter-spacing: 0;
    margin-bottom: 14px;
    padding-left: 26px;

    @include tablet {
      font-size: 35px;
      color: #FFFFFF;
      letter-spacing: 0;
      margin-bottom: 29px;
      text-align: center;
      padding-left: 0;
    }
  }

  .sec1__txt2 {
    font-family:$font2;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 1.64px;
    margin-bottom: 15px;
    padding-left: 26px;

    @include tablet {
      max-width: 307px;
      font-size: 15px;
      letter-spacing: 1.36px;
      text-align: center;
      padding-left: 0;
      margin: 0 auto 29px;
    }
  }

  .scroll-icon__wrap {
    @include flex-center;
    width: 100%;
    position: absolute;
    bottom: 97px;
    left: 0;

    @include tablet {
      display: none;
    }
  }

  .btn {
    margin-left: 26px;

    @include tablet {
      margin: 0 auto;
    }
  }

  .btn__scroll {
    @include circle(38px);
    background-image: url("../img/svg/scroll.svg");
    animation: $bouncing-icon-animation;
  }
}

.txt-default {
  font-family: $font1;
  font-size: 15px;
  line-height: 20px;

  @include tablet {
    font-size: 12px;
    letter-spacing: 1.33px;
    text-align: center;
    margin: 0 auto 25px;
    line-height: 15px;
  }
}

.main-content {
  max-width: 1240px;
  background-color: #FFFFFF;
  box-shadow: 0 14px 25px 0 rgba(0,0,0,0.24);
  border-radius: 23px;
  margin: 0 auto;
  position: relative;
  bottom: 63px;

  @include tablet {
    max-width: 573px;
    bottom: 103px;
  }

  @include mobile {
    max-width: 310px;
  }
}

.heading-title {
  font-family: $font2;
  font-weight: bold;
  font-size: 27px;
  color: #656381;
  letter-spacing: 0;
  margin-bottom: 9px;
  padding-left: 20px;

  @include tablet {
    font-size: 18px;
    color: #656381;
    letter-spacing: 0;
    text-align: center;
    margin: 0 auto 10px;
    max-width: 308px;
    padding-left: 0;
  }
}

.sec2 {
  padding-top: 50px;

  @include tablet {
    padding-top: 28px;
  }

  .sec2__txt1 {
    color: #9392A9;
    letter-spacing: 2px;
    padding-left: 20px;
    margin-bottom: 43px;

    @include tablet {
      padding-left: 0;
      margin-bottom: 16px;
    }
  }

  .trigger-wrap {
    max-width: 920px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 83px;

    @include tablet {
      max-width: 512px;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 30px;
    }

    @include mobile {
      margin-bottom: 50px;
    }
  }

  .trigger {
    max-width: 200px;


    .trigger-icon {
      @include circle(61px);
      margin: 0 auto 15px;

      @include tablet {
        @include circle(46px);
        margin-bottom: 12px;
        background-size: 100%;
      }

      @include mobile {
        margin-bottom: 12px;
      }

    }

    &:nth-child(1) {
      .trigger-icon {
        background-image: url("../img/svg/tr1.svg");
      }
    }

    &:nth-child(2) {
      .trigger-icon {
        background-image: url("../img/svg/tr2.svg");
      }

      @include mobile {
        margin-top: 40px;
      }
    }

    &:nth-child(3) {
      .trigger-icon {
        background-image: url("../img/svg/tr3.svg");
      }

      @include tablet {
        margin-top: 53px;
      }

      @include mobile {
        margin-top: 40px;
      }
    }

    .trigger-txt {
      font-family: $font1;
      font-size: 15px;
      color: #9392A9;
      letter-spacing: 1px;
      text-align: center;

      @include tablet {
        font-size: 12px;
        letter-spacing: 0.8px;
      }
    }
  }

  .services-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 57px;

    @include tablet {
      flex-direction: column;
      align-items: center;
      margin-bottom: 26px;
    }

    .service-block__wrapper {
      max-width: 355px;
      width: 100%;
      @include flex-center;
      background-color: #F9FAFF;
      padding: 21px 14px 23px;
      border-radius: 14px;

      @include tablet {
        margin-bottom: 30px;
      }


      &:nth-child(1) {

        .service-block {
          padding-top: 71px;

          .service-icon {
            width: 158px;
            height: 145px;
            background-image: url("../img/svg/serv1.svg");
            margin-bottom: 28px;
          }

          @include tablet {
            padding-top: 26px;
          }
        }
      }

      &:nth-child(2) {

        .service-block {
          padding-top: 73.5px;

          .service-icon {
            width: 148px;
            height: 116px;
            background-image: url("../img/svg/serv2.svg");
            margin-bottom: 52px;
          }

          @include tablet {
            padding-top: 21px;
          }
        }
      }

      &:nth-child(3) {

        .service-block {
          padding-top: 18px;

          .service-icon {
            width: 168px;
            height: 212px;
            margin-bottom: 10px;
            background-image: url("../img/svg/serv3.svg");

            @include tablet {
              width: 133px;
              height: 167px;
              background-size: 100%;
            }
          }
        }

        @include tablet {
          margin-bottom: 0;
        }
      }
    }

    .service-block {
      background-color: #fff;
      max-width: 327px;
      border-radius: 14px;
      min-height: 539px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 43px;
      box-shadow: 0 7px 7px -3px #D9DCFB;
      position: relative;
      @include transition(all, .3s);
      bottom: 0;

      @include tablet {
        min-height: 450px;
      }

      @include mobile {
        padding-left: 7px;
        padding-right: 7px;
      }

      &:hover {

        bottom: 10px;
        background: #FFFFFF;
        box-shadow: 0 16px 17px -3px #D1D4F9;
      }
    }

    .service-heading {
      font-family: $font2;
      font-weight: bold;
      font-size: 20px;
      color: #656381;
      letter-spacing: 0;
      margin-bottom: 21px;

      @include mobile {
        font-size: 19px;
      }
    }

    .service-ul {
      font-family: $font1;
      font-size: 15px;
      color: #9392A9;
      letter-spacing: 1px;

      @include tablet {
        text-align: center;
        font-size: 12px;
      }

      li {
        margin-bottom: 5px;

        @include mobile {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .btn {
    margin: 0 auto  ;
  }
}

.sec3 {
  @include bg-defaults(1390px);
  background-image: url("../img/svg/sec2-bg.svg");
  padding-top: 79px;
  padding-bottom: 77px;

  @include tablet {
    background-size: 1470px;
    padding-bottom: 75px;
  }

  @include mobile {
    background-size: 1620px;
    padding-bottom: 55px;
  }


  .sec3__txt1 {
    color: #9392A9;
    letter-spacing: 2px;
    margin-bottom: 43px;
    padding-left: 20px;

    @include tablet {
      padding-left: 0;
      margin-bottom: 24px;
    }
  }
}

.cooperation-block {
  display: flex;
  position: relative;
  padding-right: 53px;

  @include tablet {
    padding-right: 0;
  }
}

.cooperation-steps__visual {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  position: sticky;
  padding-left: 21px;
  top: 0;
  max-height: 260px;
  padding-top: 8px;

  @include tablet {
    display: none;
  }

  &::before {
    content: '';
    height: 205px;
    border-left: 1px solid #768CFA;
    top: 30px;
    position: absolute;
    left: 36px;

  }

  .step-txt {
    font-family: $font1;
    font-size: 13px;
    color: #2A2A2C;
    letter-spacing: 0.87px;
  }
}

.step-wrap {
  display: flex;
  align-items: center;
  width: 319px;

  &.active {
    .step-circle {
      &::before {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .step-txt {
      color: #768CFA;
    }
  }

  &:nth-child(1) {
    margin-bottom: 45px;
  }

  &:nth-child(2) {
    margin-bottom: 38px;
  }

  &:nth-child(3) {
    margin-bottom: 42px;
  }

  .step-circle {
    @include circle(32px);
    background: #ECEFFC;
    border: 1px solid #768CFA;
    position: relative;
    min-width: 32px;
    margin-right: 12px;


    &::before {
      background: #768CFA;
      @include circle(10px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }

  }
}

.cooperation-steps__txt {
  //padding-left: 370px;

  @include tablet {
    width: 100%;
  }


  .step__txt-block {
    max-width: 443px;
    width: 100%;

    @include tablet {
      margin-right: 0 !important;
    }

    &:nth-child(1) {
      margin-bottom: 118px;

      @include tablet {
        margin-bottom: 22px;
      }
    }

    &:nth-child(2) {
      margin-bottom: 94px;
    }

    &:nth-child(3) {
      margin-bottom: 118px;
    }
  }

  .step__txt-title {
    font-family: $font2;
    font-weight: bold;
    font-size: 20px;
    color: #656381;
    letter-spacing: 0;
    margin-bottom: 21px;
    padding-top: 8px;
  }

  .sec3__txt-content {
    color: #9392A9;

    @include tablet {
      text-align: left;
    }
  }

}

//.step-example {
//  max-width: 203px;
//  width: 100%;
//  padding-top: 50px;

  .step-example__content {
    display: flex;

    .step-example__txt {
      font-family: $font1;
      font-size: 8px;
      color: #9392A9;
      letter-spacing: 0.33px;
      line-height: 8px;
      padding: 2px 6px;
      background: #FFFFFF;
      box-shadow: 0 4px 6px 0 #D1D4F9;
      border-radius: 2px;
      max-height: 29px;
      max-width: 127px;
    }

    .step-example__icon {
      width: 25px;
      height: 29px;
      background: #768CFA;
      border-radius: 2px;
      margin-right: 1px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
      }
    }


  }

  .step__wrap {
    display: flex;
    align-items: center;

    @include tablet {
      flex-direction: column;
      max-width: 423px;
      margin: 0 auto;
    }

    &:nth-child(1) {
      .step-example__content {
        width: 203px;
        height: 149px;
        justify-content: flex-end;
        padding-top: 44px;
        background-image: url("../img/svg/phone.svg");
        background-size: 79.34px;
        background-position: 17px 11px;
        background-repeat: no-repeat;
        position: relative;
        bottom: 22px;
        .step-example__icon {
          &::before {
            background-image: url("../img/svg/small-phone.svg");
            width: 13.33px;
            height: 13.57px;
          }
        }


      }

      .step__txt-block {
        margin-right: 100px;
      }
    }

    &:nth-child(2) {
      .step-example__content {
        width: 181px;
        height: 175px;
        padding-top: 73px;
        background-image: url("../img/svg/human.svg");
        background-size: 57px;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position: relative;
        bottom: 54px;

        .step-example__icon {
          &::before {
            background-image: url("../img/svg/glasses.svg");
            width: 22px;
            height: 7px;
          }
        }

        @include tablet {
          bottom: 25px;
        }
      }

      .step__txt-block {
        margin-right: 106px;
      }
    }

    &:nth-child(3) {
      .step-example__content {
        width: 212px;
        height: 107px;
        padding-top: 40px;
        justify-content: flex-end;
        background-image: url("../img/svg/doc.svg");
        background-size: 87px;
        background-position: 5px 5px;
        background-repeat: no-repeat;
        position: relative;
        bottom: 20px;


        .step-example__icon {
          &::before {
            background-image: url("../img/svg/pencil.svg");
            width: 11px;
            height: 17px;
          }
        }
      }

      .step__txt-block {
        margin-right: 92px;
      }

      @include tablet {
        bottom: 25px;
      }
    }

    &:nth-child(4) {
      .step-example__content {
        width: 203px;
        height: 75px;
        padding-top: 22px;
        background-image: url("../img/svg/cent.svg");
        background-size: 75px;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position: relative;
        bottom: 39px;

        .step-example__icon {
          &::before {
            background-image: url("../img/svg/smile.svg");
            width: 15px;
            height: 15px;
          }
        }

        @include tablet {
          bottom: 25px;
        }
      }

      .step__txt-block {
        margin-right: 106px;
      }


    }
  }
//}

.sec4 {
  padding-top: 43px;
  padding-bottom: 30px;

  @include tablet {
    padding-top: 0;
  }

  .sec4__txt1 {
    padding-left: 20px;
    margin-bottom: 43px;

    @include tablet {
      padding-left: 0;
      max-width: 297px;
      margin-bottom: 15px;
    }
  }

  .txt-default {
    color: #9392A9;
  }

  .slider {
    width: 1000px;
    margin: 0 auto;

    @include tablet {
      width: 418px;
    }

    @include mobile {
      width: 100%;
    }
  }

  .slide {
    display: flex !important;
    align-items: center;
    padding-left: 63px;
    outline: none;

    @include tablet {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .slider__pick-wrap {
    @include circle(239px);
    @include flex-center;
    background: rgba(107,124,245,0.06);
    min-width: 239px;
    margin-right: 42px;

    @include tablet {
      @include circle(157px);
      min-width: 157px;
      margin-right: 0;
    }

    .slider__pic {
      background-image: url("../img/photo-example.png");
      background-size: 100%;
      @include circle(210px);

      @include tablet {
        @include circle(138px);
      }
    }
  }

  .content__title {
    font-family: $font2;
    font-weight: bold;
    font-size: 20px;
    color: #656381;
    letter-spacing: 0;
    margin-bottom: 21px;

    @include tablet {
      font-size: 15px;
      letter-spacing: 0;
      text-align: center;
      margin-top: 15px;
    }
  }

  .slick-dots {
    li {
      button {
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          background: #FFFFFF;
          box-shadow: 0 14px 25px 0 rgba(0,0,0,0.24);
          border: 1px solid #768CFA;
          border-radius: 50%;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &::before {
            background: #768CFA;
          }
        }
      }
    }
  }
}

.sec5 {
  padding-top: 112px;
  padding-bottom: 98px;

  @include tablet {
    padding-top: 70px;
    padding-bottom: 54px;
  }

  @include mobile {
    padding-top: 50px;
  }

  .heading-title,
  .txt-default {
    text-align: center;
  }

  .txt-default {
    color: #9392A9;
    margin-bottom: 25px;

    @include tablet {
      max-width: 254px;
      margin-bottom: 15px;
    }
  }


}

.standart-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    max-width: 363px;
    width: 100%;
    font-family: $font1;
    font-size: 15px;
    color: #2A2A2C;
    letter-spacing: 1.5px;
    padding: 13px 12px 14px;
    border: 1px solid rgba(0,0,0,.3);
    border-radius: 9px;
    margin-bottom: 17px;
    outline: none;
    box-sizing: border-box;

    @include tablet {
      max-width: 295px;
      font-size: 12px;
      letter-spacing: 1.2px;
    }
  }

  .phone {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 40px;

    @include tablet {
      margin-top: 25px;
    }
  }
}

.map-wrap {
  width: 100%;
  height: 563px;

  @include tablet {
    margin-top: -280px;
  }
}

.mobile-nav {
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background: rgba(255,255,255, .8);
  @include flex-center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  @include transition(all, .4s);

  &.active {
    opacity: 1;
    visibility: visible;
  }
  li {
    list-style-type: none;
    font-size: 38px;
    margin-bottom: 10px;
    font-family: $font2;
    text-align: center;

    @include mobile {
      font-size: 29px;
    }

    a {
      color: #2A2A2C;
    }
  }

  .close {
    width: 20px;
    height: 20px;
    background-image: url("../img/svg/cancel.svg");
    position: fixed;
    right: 30px;
    top: 30px;
    background-size: 100%;
    cursor: pointer;
  }
}